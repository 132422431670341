import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import "../../../styles/portfolio.scss";
import bawainsta from "../../../images/bawainsta.jpg";
import { gsap } from "gsap";

export default function JayceePortfolio() {
  var colors = ["#f0f0f0"];

  const col1 = [
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/july1.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/july2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/july3.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/sep1.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/sep2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/sep3.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/sep4.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/sep5.jpg",
    },
  ];

  const col2 = [
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct3.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct4.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct5.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct6.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct7.jpg",
    },

    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct1.jpg",
    },
  ];

  const col3 = [
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct10.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct11.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/nov1.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/nov2.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/nov3.jpg",
    },

    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct8.jpg",
    },
    {
      img: "https://blackelephantdigital.com/assets/portfolio/jaycee/oct9.jpg",
    },
  ];

  const [updateComponent, setUpdateComponent] = useState(0);

  // function handleClick() {}

  useEffect(() => {
    var w = window.innerWidth;

    let animboxes = gsap.set(".box", {
      backgroundColor: (i) => colors[Math.floor(Math.random() * 8)],
      y: (i) => (i * w) / 4,
      force3D: true,
    });

    let anim1 = gsap.to(".wrapper1 .box", {
      duration: 49,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize((y) => parseFloat(y) % ((w / 4) * col1.length)), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
      force3D: true,
    });
    let anim2 = gsap.to(".wrapper2 .box", {
      duration: 51,
      ease: "none",
      y: "-=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize((y) => parseFloat(y) % ((w / 4) * col2.length)), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
      force3D: true,
    });
    let anim3 = gsap.to(".wrapper3 .box", {
      duration: 48,
      ease: "none",
      y: "+=" + (w / 4) * 10, //move each box 500px to right
      modifiers: {
        y: gsap.utils.unitize((y) => parseFloat(y) % ((w / 4) * col3.length)), //force x value to be between 0 and 500 using modulus
      },
      repeat: -1,
      force3D: true,
    });

    window.addEventListener("resize", () => {
      anim1.kill();
      anim2.kill();
      anim3.kill();

      animboxes.kill();

      const boxes = document.querySelectorAll(".box");

      boxes.forEach((item) => {
        item.style = "";
      });
      setUpdateComponent(updateComponent);
      w = window.innerWidth;
      animboxes = gsap.set(".box", {
        backgroundColor: (i) => colors[i % colors.length],
        y: (i) => (i * w) / 4,
      });

      anim1 = gsap.to(".wrapper1 .box", {
        duration: 49,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize((y) => parseFloat(y) % ((w / 4) * col1.length)), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
        force3D: true,
      });
      anim2 = gsap.to(".wrapper2 .box", {
        duration: 51,
        ease: "none",
        y: "-=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize((y) => parseFloat(y) % ((w / 4) * col1.length)), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
        force3D: true,
      });
      anim3 = gsap.to(".wrapper3 .box", {
        duration: 48,
        ease: "none",
        y: "+=" + (w / 4) * 10, //move each box 500px to right
        modifiers: {
          y: gsap.utils.unitize((y) => parseFloat(y) % ((w / 4) * col3.length)), //force x value to be between 0 and 500 using modulus
        },
        repeat: -1,
        force3D: true,
      });
    });
  }, []);

  return (
    <Layout>
      <div className="portfolio">
        <div
          style={{ backgroundColor: "#F1813A" }}
          className="portfolio__container"
        >
          <div className="portfolio__client">
            <div className="portfolio__client__logo">
              <img
                className=""
                src="https://www.jayceepower.com/images/newImages/JC_updated_logo.png"
              ></img>
              {/* <div>Jaycee Power</div> */}
            </div>
          </div>

          <div className="portfolio__wrappers">
            <div class="wrapper wrapper1">
              <div class="boxes">
                {col1.map((item, i) => (
                  <div className="box" key={`col1-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper2">
              <div class="boxes">
                {col2.map((item, i) => (
                  <div className="box" key={`col2-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
            <div class="wrapper wrapper3">
              <div class="boxes">
                {col3.map((item, i) => (
                  <div className="box" key={`col3-${i}`}>
                    <img src={item.img}></img>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
